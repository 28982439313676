import _extends from "/home/vsts/work/1/s/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
var __jsx = React.createElement;
import * as React from 'react';
function FileIcon2(props) {
  return __jsx("svg", _extends({
    viewBox: "0 0 56 56"
  }, props), __jsx("defs", null, __jsx("style", null, '.prefix__cls-2{fill:#8871f7;stroke:#8871f7;stroke-width:.5px}')), __jsx("g", {
    id: "prefix__Icon",
    transform: "translate(6.523 5.177)"
  }, __jsx("path", {
    id: "prefix__Ellipse",
    d: "M0 33.3c.578 14.53 25.613 37.226 49.318 9.869 10.916-13.468 6.636-27.267 0-33.721S39.354 1.762 32.494.614-.4-3.811 0 33.3z",
    transform: "rotate(-90 22.15 28.673)",
    opacity: 0.1,
    fill: "#8871f7"
  }), __jsx("g", {
    id: "prefix__Icon-2",
    "data-name": "Icon",
    transform: "translate(139.72 142)"
  }, __jsx("path", {
    id: "prefix__Path_1069",
    d: "M42.2 41.88h-9.909a.315.315 0 00-.291.334v4.012h.07a.364.364 0 00-.07.211v4.012a.364.364 0 00.07.211H32v3.678a.315.315 0 00.291.334H42.2a.315.315 0 00.291-.334V50.66h-.07a.365.365 0 00.07-.214v-4.012a.364.364 0 00-.07-.207h.07v-4.013a.315.315 0 00-.291-.334zm-.295 4.22h-4.37v-3.551h4.37zm-4.37.669h4.37v3.343h-4.37zm-.583 3.343h-4.37v-3.344h4.37zm0-7.563V46.1h-4.37v-3.551zm-4.37 8.232h4.37V54h-4.37zM37.535 54v-3.22h4.37V54z",
    className: "prefix__cls-2",
    "data-name": "Path 1069",
    transform: "translate(-155.986 -164.17)"
  }), __jsx("path", {
    id: "prefix__Path_1070",
    d: "M29.825 5.37H16.591a.313.313 0 00-.311.314v27.372a.313.313 0 00.311.314h20.378a.313.313 0 00.311-.314V13.03a.315.315 0 00-.09-.22l-7.14-7.346a.31.31 0 00-.225-.094zm.262 1.03l6.433 6.616h-6.433zM16.9 32.752V6h12.564v7.334a.313.313 0 00.311.314h6.882v19.1z",
    className: "prefix__cls-2",
    "data-name": "Path 1070",
    transform: "translate(-145.522 -138.547)"
  }))));
}
export default FileIcon2;