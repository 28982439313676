import _extends from "/home/vsts/work/1/s/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
var __jsx = React.createElement;
import * as React from 'react';
function FileIcon1(props) {
  return __jsx("svg", _extends({
    viewBox: "0 0 56 56"
  }, props), __jsx("defs", null, __jsx("style", null, '.prefix__cls-2{fill:#8871f7;stroke:#8871f7;stroke-width:.5px}')), __jsx("g", {
    id: "prefix__Icon",
    transform: "translate(6.523 5.177)"
  }, __jsx("path", {
    id: "prefix__Ellipse",
    d: "M0 33.3c.578 14.53 25.613 37.226 49.318 9.869 10.916-13.468 6.636-27.267 0-33.721S39.354 1.762 32.494.614-.4-3.811 0 33.3z",
    transform: "rotate(-90 22.15 28.673)",
    opacity: 0.1,
    fill: "#8871f7"
  }), __jsx("g", {
    id: "prefix__Icon-2",
    "data-name": "Icon",
    transform: "translate(9.478 8.823)"
  }, __jsx("path", {
    id: "prefix__Path_1066",
    d: "M26.94 7.37a2.854 2.854 0 00-2.709 1.97H15.44v26.03h23V9.324h-8.791A2.855 2.855 0 0026.94 7.37zm10.834 27.343H16.106V9.981h5.926v3.793h9.816V9.981h5.926zM29.123 9.978h2.06v3.136H22.7V9.981h2.06l.057-.259a2.183 2.183 0 014.253 0z",
    className: "prefix__cls-2",
    "data-name": "Path 1066",
    transform: "translate(-15.44 -7.37)"
  }), __jsx("path", {
    id: "prefix__Rectangle_214",
    d: "M0 0h6.539v.657H0z",
    className: "prefix__cls-2",
    "data-name": "Rectangle 214",
    transform: "translate(12.482 12.66)"
  }), __jsx("path", {
    id: "prefix__Path_1067",
    d: "M30.77 43l-1.351-1.08-.419.509 1.853 1.481 3.131-3.593-.506-.427z",
    className: "prefix__cls-2",
    "data-name": "Path 1067",
    transform: "translate(-24.488 -29.21)"
  }), __jsx("path", {
    id: "prefix__Rectangle_215",
    d: "M0 0h6.539v.657H0z",
    className: "prefix__cls-2",
    "data-name": "Rectangle 215",
    transform: "translate(12.315 20.401)"
  }), __jsx("path", {
    id: "prefix__Path_1068",
    d: "M32.47 64.03l-1.68 1.658-1.677-1.658-.473.466 1.68 1.658-1.68 1.656.473.463 1.677-1.655 1.68 1.655.469-.463-1.677-1.655 1.677-1.655z",
    className: "prefix__cls-2",
    "data-name": "Path 1068",
    transform: "translate(-24.248 -45.422)"
  }))));
}
export default FileIcon1;