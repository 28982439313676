import _extends from "/home/vsts/work/1/s/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
var __jsx = React.createElement;
import * as React from 'react';
function FileIcon3(props) {
  return __jsx("svg", _extends({
    viewBox: "0 0 56 56"
  }, props), __jsx("path", {
    d: "M33.3 56c14.53-.578 37.226-25.613 9.869-49.318-13.468-10.916-27.267-6.636-33.721 0S1.762 16.646.614 23.506-3.811 56.4 33.3 56z",
    opacity: 0.1,
    fill: "#8871f7"
  }), __jsx("g", {
    "data-name": "Icon"
  }, __jsx("path", {
    d: "M17.001 14.31v23.648a.322.322 0 00.309.311h3.391v2.8a1.054 1.054 0 00.926.934h11.127a.905.905 0 00.405-.287c1.583-1.5 3.364-3.17 4.739-4.463a.316.316 0 00.1-.224q0-9.184.01-18.363a1.416 1.416 0 00-.936-.929h-2.77v-3.423a.322.322 0 00-.309-.311H17.28c-.193.04-.222.2-.279.311zm.618.311h16.058v3.116H21.624a1.054 1.054 0 00-.926.933v18.977h-3.079zm4.005 3.734h15.364a.752.752 0 01.4.307q0 8.868-.01 17.74h-5.24v4.979H21.624c-.188-.041-.231-.161-.309-.311v-22.4c.06-.182.191-.235.309-.311zm2.471 4.045v.622h10.5V22.4zm0 4.356v.622h10.5v-.622zm0 4.356v.622h7.412v-.622zm8.657 5.912h4.478c-1.689 1.582-3.005 2.847-4.478 4.22z",
    "data-name": "Path 1072",
    stroke: "#8871f7",
    strokeWidth: 0.5,
    fill: "#8871f7"
  })));
}
export default FileIcon3;