import _extends from "/home/vsts/work/1/s/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
var __jsx = React.createElement;
import * as React from 'react';
function FileIcon4(props) {
  return __jsx("svg", _extends({
    viewBox: "0 0 56 56"
  }, props), __jsx("defs", null, __jsx("style", null, '.prefix__cls-2{fill:#8871f7;stroke:#8871f7;stroke-width:.5px}')), __jsx("g", {
    id: "prefix__Icon",
    transform: "translate(6.523 5.177)"
  }, __jsx("path", {
    id: "prefix__Ellipse",
    d: "M0 33.3c.578 14.53 25.613 37.226 49.318 9.869 10.916-13.468 6.636-27.267 0-33.721S39.354 1.762 32.494.614-.4-3.811 0 33.3z",
    transform: "rotate(-90 22.15 28.673)",
    opacity: 0.1,
    fill: "#8871f7"
  }), __jsx("g", {
    id: "prefix__Icon-2",
    "data-name": "Icon",
    transform: "translate(-16.522 -11.583)"
  }, __jsx("path", {
    id: "prefix__Path_1073",
    d: "M41.54 20.542a.478.478 0 00-.335-.137H28.447a.475.475 0 00-.477.473v27.054a.475.475 0 00.477.473h20.047a.475.475 0 00.477-.473v-19.9a.471.471 0 00-.141-.336zm.141 1.469l5.652 5.547h-4.46a1.373 1.373 0 01-1.192-1.1zM28.923 47.459V21.351h11.8v5.146a2.335 2.335 0 002.019 2 .48.48 0 00.081.007h5.187v18.955z",
    className: "prefix__cls-2",
    "data-name": "Path 1073"
  }), __jsx("path", {
    id: "prefix__Path_1074",
    d: "M33.7 42.889h11.38a.48.48 0 000-.96H33.7a.48.48 0 000 .96z",
    className: "prefix__cls-2",
    "data-name": "Path 1074",
    transform: "translate(-2.73 -11.347)"
  }), __jsx("path", {
    id: "prefix__Path_1075",
    d: "M43.053 58.253H33.7a.48.48 0 000 .96h9.353a.48.48 0 100-.96z",
    className: "prefix__cls-2",
    "data-name": "Path 1075",
    transform: "translate(-2.73 -19.953)"
  }), __jsx("path", {
    id: "prefix__Path_1076",
    d: "M33.7 50.857h14.16a.48.48 0 000-.96H33.7a.48.48 0 000 .96z",
    className: "prefix__cls-2",
    "data-name": "Path 1076",
    transform: "translate(-2.73 -15.548)"
  }), __jsx("path", {
    id: "prefix__Path_1077",
    d: "M57.5 61.427a2.76 2.76 0 102.76 2.76 2.76 2.76 0 00-2.76-2.76zm0 4.56a1.8 1.8 0 111.8-1.8 1.8 1.8 0 01-1.8 1.8z",
    className: "prefix__cls-2",
    "data-name": "Path 1077",
    transform: "translate(-14.067 -21.741)"
  }))));
}
export default FileIcon4;