import _objectWithoutProperties from "/home/vsts/work/1/s/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/objectWithoutProperties.js";
var _excluded = ["className", "background", "children"];
var __jsx = React.createElement;
import React from 'react';
import styled from 'styled-components';
import styles from './ImageOverlay.style';
var StyleSection = styled.section.withConfig({
  componentId: "sc-10n47b9-0"
})(["", ";"], styles);
var ImageOverlay = function ImageOverlay(_ref) {
  var className = _ref.className,
    background = _ref.background,
    children = _ref.children,
    others = _objectWithoutProperties(_ref, _excluded);
  return __jsx(StyleSection, {
    className: "nva-image-overlay ".concat(className),
    background: background
  }, children);
};

// ImageOverlay.defaultProps = {
//   space: 'None'
// };

export default styled(ImageOverlay).withConfig({
  componentId: "sc-10n47b9-1"
})(["", ";"], styles);
export { ImageOverlay as ImageOverlayVanilla };